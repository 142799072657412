import {useSearchParams} from '@remix-run/react';

import {useTranslations} from '@/components/shared/Page/I18N';

export const usePaginatedPageTitle = (title: string): string => {
  const {t} = useTranslations();
  const [searchParams] = useSearchParams();

  const pageNumber = searchParams.get('page');
  const pageNumberPart =
    Number(pageNumber) > 1
      ? `| ${t('global:pagination.page', {defaultValue: 'Page'})} ${pageNumber}`
      : null;

  return pageNumberPart ? `${title} ${pageNumberPart}` : title;
};
